// TODO TIMELINE - chybí poslední 6.tučňák
import React, { Component } from "react"
import { Helmet } from "react-helmet"
import { ContactForm, Layout } from "../../../components"
import { Cubes, Medailon, PaddingWrapper } from "@igloonet-web/shared-ui"
import Logo from "../../../images/reference/domy-dnes/logo-domydnes.png"
import Screen from "../../../images/reference/domy-dnes/dd.png"
import Wireframe from "../../../images/reference/domy-dnes/wireframe1.png"
import Design from "../../../images/reference/domy-dnes/design.png"
import Kepi from "../../../images/team/mini/kepi.jpg"
import Tesar from "../../../images/reference/domy-dnes/tesar.jpg"
import ReferenceHeader from "../../../components/reference-detail/reference-header"
import ReferenceImageText from "../../../components/reference-detail/reference-image-text"
import PenguinsTimeline from "../../../components/reference-detail/penguins-timeline"
import { TIMELINE_DD } from "../../../model/reference-timeline-dd.tsx"

class DomyDnes extends Component {
  constructor({ props }) {
    super(props)
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <title>Nový web pro DOMY D.N.E.S. | igloonet</title>
          <meta
            name="description"
            content="Reference vytvoření nového webu pro experty na dřevostavby DOMY D.N.E.S."
          />
        </Helmet>

        <ReferenceHeader
          companyName="domy d.n.e.s."
          heading="Vytvoření nového webu"
          date="srpen 2014 - listopad 2020"
        />

        <ReferenceImageText
          right
          img={Screen}
          eager
          alt=""
          logo={Logo}
          logoAlt="logo společnosti Domy D.N.E.S."
          link="//domy-dnes.cz/"
        >
          <p>
            Prvotní zadání: vytvořit nový, reprezentativní web, který zvýší
            počet objednávek z internetu a umožní přidávat aktuální informace.
          </p>
          <h2>Cíle webu</h2>
          <ul>
            <li>Získávat relevantní objednávky přímo z webu.</li>
            <li>Umožnit snadnou prezentaci aktuálních typových domů.</li>
            <li>Představit rozestavěné projekty.</li>
          </ul>
        </ReferenceImageText>

        <PaddingWrapper>
          <ReferenceImageText responsiveTextOrder img={Wireframe} alt="">
            <h2>Analýza a wireframy</h2>
            <p>
              Protože má nový web sloužit primárně potenciálním zákazníkům,
              započali jsme analýzu hloubkovými rozhovory s těmi, co s DOMY už
              stavěli či stavět teprve plánují.
            </p>
            <p>
              Na výslednou podobu webu měl velký vliv workshop se zaměstnanci
              DOMŮ, kteří se v průběhu prací s koncovými zákazníky potkávají.
            </p>
            <Cubes
              data={[
                {
                  description: "Hloubkových rozhovorů",
                  number: "5",
                },
                {
                  description: "Workshopy s klientem",
                  number: "3",
                },
                {
                  description: "Hodin nad wireframy",
                  number: "30",
                },
              ]}
            />
          </ReferenceImageText>
        </PaddingWrapper>

        <PenguinsTimeline data={TIMELINE_DD} />

        <PaddingWrapper>
          <Medailon
            personName="Ondra Kudlík"
            position="UX na projektu"
            img={Kepi}
          >
            <p>
              Už po prvních dvou rozhovorech s koncovými zákazníky se začala
              prvotní „dojmová“ koncepce nového webu hroutit.
            </p>
          </Medailon>
        </PaddingWrapper>

        <PaddingWrapper>
          <ReferenceImageText halfImgSize right img={Design} alt="">
            <h2>Design</h2>
            <p>
              U designu jsme se zapotili nejvíc. Navrhovat jej pro společnost,
              kde výsledek hodnotí architekti, prostě není nejsnadnější. Zásadně
              ale pomohlo, že klient od začátku důvěřoval našemu úsudku a
              zjištěným informacím.
            </p>
          </ReferenceImageText>
        </PaddingWrapper>

        <PaddingWrapper>
          <Medailon
            personName="Tomáš Tesař"
            position="jednatel DOMY D.N.E.S."
            img={Tesar}
          >
            <p>
              V igloonetu nás rychle přesvědčili, že tak jako nemá smysl stavět
              dům bez projektu, nemá smysl vytvářet web bez analýzy a
              rozmýšlení. Museli jsme sáhnout hlouběji do kapsy, než jsme si
              představovali, ale výsledek stojí za to.
            </p>
          </Medailon>
        </PaddingWrapper>

        <ReferenceImageText>
          <h2>Shrnutí</h2>
          <p>
            Vytvoření nového webu trvalo, kvůli vytíženosti klienta, mnohem
            déle, než je obvyklé. Díky provedené analýze, rozhovorům a dostatku
            informací se nám však i přes značný časový odstup začátku a konce
            projektu podařilo spustit relevantní a moderní web, který plní
            stanovené cíle.
          </p>
        </ReferenceImageText>
        <ContactForm contact="sobol" />
      </Layout>
    )
  }
}

export default DomyDnes
